/* roboto-100 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/roboto-v18-latin_cyrillic-100.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin'), local('Roboto-Thin'),
         url('../fonts/roboto-v18-latin_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-100.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-100italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/roboto-v18-latin_cyrillic-100italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
         url('../fonts/roboto-v18-latin_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-300 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v18-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
         url('../fonts/roboto-v18-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-300italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/roboto-v18-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
         url('../fonts/roboto-v18-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v18-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('../fonts/roboto-v18-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/roboto-v18-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Italic'), local('Roboto-Italic'),
         url('../fonts/roboto-v18-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v18-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
         url('../fonts/roboto-v18-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/roboto-v18-latin_cyrillic-500italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
         url('../fonts/roboto-v18-latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v18-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
         url('../fonts/roboto-v18-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/roboto-v18-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
         url('../fonts/roboto-v18-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-900 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto-v18-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black'), local('Roboto-Black'),
         url('../fonts/roboto-v18-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-900.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-900italic - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/roboto-v18-latin_cyrillic-900italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
         url('../fonts/roboto-v18-latin_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v18-latin_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v18-latin_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v18-latin_cyrillic-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }

  /* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

// @font-face {
// 	font-family: 'Proxima Nova Cn Lt';
// 	src: url('../fonts/ProximaNovaCond-LightIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Light Italic'), local('../fonts/ProximaNovaCond-LightIt'),
// 		url('../fonts/ProximaNovaCond-LightIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-LightIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-LightIt.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Lt';
// 	src: url('../fonts/ProximaNova-LightIt.eot');
// 	src: local('../fonts/Proxima Nova Light Italic'), local('../fonts/ProximaNova-LightIt'),
// 		url('../fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-LightIt.woff') format('woff'),
// 		url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Rg';
// 	src: url('../fonts/ProximaNovaCond-Regular.eot');
// 	src: local('../fonts/Proxima Nova Condensed Regular'), local('../fonts/ProximaNovaCond-Regular'),
// 		url('../fonts/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Regular.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Regular.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// }

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('../fonts/ProximaNova-Extrabld.eot');
	src: local('../fonts/Proxima Nova Extrabold'), local('../fonts/ProximaNova-Extrabld'),
		url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Extrabld.woff') format('woff'),
		url('../fonts/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Proxima Nova Cn Bl';
// 	src: url('../fonts/ProximaNovaCond-Black.eot');
// 	src: local('../fonts/Proxima Nova Condensed Black'), local('../fonts/ProximaNovaCond-Black'),
// 		url('../fonts/ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Black.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Black.ttf') format('truetype');
// 	font-weight: 900;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Th';
// 	src: url('../fonts/ProximaNovaCond-ExtrabldIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Extrabold Italic'), local('../fonts/ProximaNovaCond-ExtrabldIt'),
// 		url('../fonts/ProximaNovaCond-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-ExtrabldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-ExtrabldIt.ttf') format('truetype');
// 	font-weight: 800;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Rg';
// 	src: url('../fonts/ProximaNova-Regular.eot');
// 	src: local('../fonts/Proxima Nova Regular'), local('../fonts/ProximaNova-Regular'),
// 		url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-Regular.woff') format('woff'),
// 		url('../fonts/ProximaNova-Regular.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Lt';
// 	src: url('../fonts/ProximaNova-Semibold.eot');
// 	src: local('../fonts/Proxima Nova Semibold'), local('../fonts/ProximaNova-Semibold'),
// 		url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-Semibold.woff') format('woff'),
// 		url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Th';
// 	src: url('../fonts/ProximaNovaExCn-Thin.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Thin'), local('../fonts/ProximaNovaExCn-Thin'),
// 		url('../fonts/ProximaNovaExCn-Thin.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Thin.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Thin.ttf') format('truetype');
// 	font-weight: 100;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Lt';
// 	src: url('../fonts/ProximaNovaCond-Semibold.eot');
// 	src: local('../fonts/Proxima Nova Condensed Semibold'), local('../fonts/ProximaNovaCond-Semibold'),
// 		url('../fonts/ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Semibold.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Semibold.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Lt';
// 	src: url('../fonts/ProximaNovaExCn-SemiboldIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Semibold Italic'), local('../fonts/ProximaNovaExCn-SemiboldIt'),
// 		url('../fonts/ProximaNovaExCn-SemiboldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-SemiboldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-SemiboldIt.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Lt';
// 	src: url('../fonts/ProximaNovaExCn-Semibold.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Semibold'), local('../fonts/ProximaNovaExCn-Semibold'),
// 		url('../fonts/ProximaNovaExCn-Semibold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Semibold.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Semibold.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: normal;
// }

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('../fonts/ProximaNova-ThinIt.eot');
	src: local('../fonts/Proxima Nova Thin Italic'), local('../fonts/ProximaNova-ThinIt'),
		url('../fonts/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-ThinIt.woff') format('woff'),
		url('../fonts/ProximaNova-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

// @font-face {
// 	font-family: 'Proxima Nova Cn Th';
// 	src: url('../fonts/ProximaNovaCond-Thin.eot');
// 	src: local('../fonts/Proxima Nova Condensed Thin'), local('../fonts/ProximaNovaCond-Thin'),
// 		url('../fonts/ProximaNovaCond-Thin.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Thin.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Thin.ttf') format('truetype');
// 	font-weight: 100;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Rg';
// 	src: url('../fonts/ProximaNovaCond-RegularIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Regular Italic'), local('../fonts/ProximaNovaCond-RegularIt'),
// 		url('../fonts/ProximaNovaCond-RegularIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-RegularIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-RegularIt.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Th';
// 	src: url('../fonts/ProximaNovaExCn-ThinIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Thin Italic'), local('../fonts/ProximaNovaExCn-ThinIt'),
// 		url('../fonts/ProximaNovaExCn-ThinIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-ThinIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-ThinIt.ttf') format('truetype');
// 	font-weight: 100;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Lt';
// 	src: url('../fonts/ProximaNova-SemiboldIt.eot');
// 	src: local('../fonts/Proxima Nova Semibold Italic'), local('../fonts/ProximaNova-SemiboldIt'),
// 		url('../fonts/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-SemiboldIt.woff') format('woff'),
// 		url('../fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Rg';
// 	src: url('../fonts/ProximaNova-RegularIt.eot');
// 	src: local('../fonts/Proxima Nova Regular Italic'), local('../fonts/ProximaNova-RegularIt'),
// 		url('../fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-RegularIt.woff') format('woff'),
// 		url('../fonts/ProximaNova-RegularIt.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Th';
// 	src: url('../fonts/ProximaNovaCond-Extrabld.eot');
// 	src: local('../fonts/Proxima Nova Condensed Extrabold'), local('../fonts/ProximaNovaCond-Extrabld'),
// 		url('../fonts/ProximaNovaCond-Extrabld.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Extrabld.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Extrabld.ttf') format('truetype');
// 	font-weight: 800;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Th';
// 	src: url('../fonts/ProximaNovaExCn-ExtrabldIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Extrabold Italic'), local('../fonts/ProximaNovaExCn-ExtrabldIt'),
// 		url('../fonts/ProximaNovaExCn-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-ExtrabldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-ExtrabldIt.ttf') format('truetype');
// 	font-weight: 800;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Bl';
// 	src: url('../fonts/ProximaNovaExCn-BlackIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Black Italic'), local('../fonts/ProximaNovaExCn-BlackIt'),
// 		url('../fonts/ProximaNovaExCn-BlackIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-BlackIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-BlackIt.ttf') format('truetype');
// 	font-weight: 900;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Lt';
// 	src: url('../fonts/ProximaNovaExCn-LightIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Light Italic'), local('../fonts/ProximaNovaExCn-LightIt'),
// 		url('../fonts/ProximaNovaExCn-LightIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-LightIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-LightIt.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Th';
// 	src: url('../fonts/ProximaNovaCond-ThinIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Thin Italic'), local('../fonts/ProximaNovaCond-ThinIt'),
// 		url('../fonts/ProximaNovaCond-ThinIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-ThinIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-ThinIt.ttf') format('truetype');
// 	font-weight: 100;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Lt';
// 	src: url('../fonts/ProximaNovaExCn-Light.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Light'), local('../fonts/ProximaNovaExCn-Light'),
// 		url('../fonts/ProximaNovaExCn-Light.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Light.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Rg';
// 	src: url('../fonts/ProximaNovaCond-BoldIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Bold Italic'), local('../fonts/ProximaNovaCond-BoldIt'),
// 		url('../fonts/ProximaNovaCond-BoldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-BoldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-BoldIt.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Th';
// 	src: url('../fonts/ProximaNovaExCn-Extrabld.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Extrabold'), local('../fonts/ProximaNovaExCn-Extrabld'),
// 		url('../fonts/ProximaNovaExCn-Extrabld.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Extrabld.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Extrabld.ttf') format('truetype');
// 	font-weight: 800;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Rg';
// 	src: url('../fonts/ProximaNovaExCn-BoldIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Bold Italic'), local('../fonts/ProximaNovaExCn-BoldIt'),
// 		url('../fonts/ProximaNovaExCn-BoldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-BoldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-BoldIt.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: italic;
// }

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('../fonts/ProximaNova-Black.eot');
	src: local('../fonts/Proxima Nova Black'), local('../fonts/ProximaNova-Black'),
		url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Black.woff') format('woff'),
		url('../fonts/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Proxima Nova Rg';
// 	src: url('../fonts/ProximaNova-Bold.eot');
// 	src: local('../fonts/Proxima Nova Bold'), local('../fonts/ProximaNova-Bold'),
// 		url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-Bold.woff') format('woff'),
// 		url('../fonts/ProximaNova-Bold.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Rg';
// 	src: url('../fonts/ProximaNovaExCn-Bold.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Bold'), local('../fonts/ProximaNovaExCn-Bold'),
// 		url('../fonts/ProximaNovaExCn-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Bold.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Bold.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: normal;
// }

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('../fonts/ProximaNovaT-Thin.eot');
	src: local('../fonts/Proxima Nova Thin'), local('../fonts/ProximaNovaT-Thin'),
		url('../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNovaT-Thin.woff') format('woff'),
		url('../fonts/ProximaNovaT-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Proxima Nova Rg';
// 	src: url('../fonts/ProximaNova-BoldIt.eot');
// 	src: local('../fonts/Proxima Nova Bold Italic'), local('../fonts/ProximaNova-BoldIt'),
// 		url('../fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-BoldIt.woff') format('woff'),
// 		url('../fonts/ProximaNova-BoldIt.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Rg';
// 	src: url('../fonts/ProximaNovaCond-Bold.eot');
// 	src: local('../fonts/Proxima Nova Condensed Bold'), local('../fonts/ProximaNovaCond-Bold'),
// 		url('../fonts/ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Bold.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Bold.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Bl';
// 	src: url('../fonts/ProximaNovaExCn-Black.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Black'), local('../fonts/ProximaNovaExCn-Black'),
// 		url('../fonts/ProximaNovaExCn-Black.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Black.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Black.ttf') format('truetype');
// 	font-weight: 900;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Rg';
// 	src: url('../fonts/ProximaNovaExCn-RegularIt.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Regular Italic'), local('../fonts/ProximaNovaExCn-RegularIt'),
// 		url('../fonts/ProximaNovaExCn-RegularIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-RegularIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-RegularIt.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Lt';
// 	src: url('../fonts/ProximaNovaCond-Light.eot');
// 	src: local('../fonts/Proxima Nova Condensed Light'), local('../fonts/ProximaNovaCond-Light'),
// 		url('../fonts/ProximaNovaCond-Light.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-Light.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// }

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('../fonts/ProximaNova-ExtrabldIt.eot');
	src: local('../fonts/Proxima Nova Extrabold Italic'), local('../fonts/ProximaNova-ExtrabldIt'),
		url('../fonts/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-ExtrabldIt.woff') format('woff'),
		url('../fonts/ProximaNova-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

// @font-face {
// 	font-family: 'Proxima Nova Cn Bl';
// 	src: url('../fonts/ProximaNovaCond-BlackIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Black Italic'), local('../fonts/ProximaNovaCond-BlackIt'),
// 		url('../fonts/ProximaNovaCond-BlackIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-BlackIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-BlackIt.ttf') format('truetype');
// 	font-weight: 900;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'Proxima Nova ExCn Rg';
// 	src: url('../fonts/ProximaNovaExCn-Regular.eot');
// 	src: local('../fonts/Proxima Nova Extra Condensed Regular'), local('../fonts/ProximaNovaExCn-Regular'),
// 		url('../fonts/ProximaNovaExCn-Regular.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaExCn-Regular.woff') format('woff'),
// 		url('../fonts/ProximaNovaExCn-Regular.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Lt';
// 	src: url('../fonts/ProximaNova-Light.eot');
// 	src: local('../fonts/Proxima Nova Light'), local('../fonts/ProximaNova-Light'),
// 		url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNova-Light.woff') format('woff'),
// 		url('../fonts/ProximaNova-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Proxima Nova Cn Lt';
// 	src: url('../fonts/ProximaNovaCond-SemiboldIt.eot');
// 	src: local('../fonts/Proxima Nova Condensed Semibold Italic'), local('../fonts/ProximaNovaCond-SemiboldIt'),
// 		url('../fonts/ProximaNovaCond-SemiboldIt.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/ProximaNovaCond-SemiboldIt.woff') format('woff'),
// 		url('../fonts/ProximaNovaCond-SemiboldIt.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: italic;
// }

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('../fonts/ProximaNova-BlackIt.eot');
	src: local('../fonts/Proxima Nova Black Italic'), local('../fonts/ProximaNova-BlackIt'),
		url('../fonts/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-BlackIt.woff') format('woff'),
		url('../fonts/ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
