@import './scss/fonts.scss';
@import 'font-awesome-sass/assets/stylesheets/_font-awesome.scss';
@import '~bootstrap/scss/bootstrap';
@import '~fullpage.js/dist/fullpage.css';
$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';
/*margin-paddin -col -0 */

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.col,
[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

body,
html {
    margin: 0;
    padding: 0;
    color: #333333;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.section {
    padding: 0 320px;
}

* {
    box-sizing: border-box;
}

a {
    color: #2589b3;
}

a:hover {
    color: #eb3e4a;
    text-decoration: none;
}

h1,
h2 {
    margin: 0;
}

h2 {
    margin-top: 40px;
}

/*left - right side*/

.fixed-left-side,
.fixed-right-side {
    width: 320px;
    height: 100vh;
    position: fixed;
    z-index: 1;
}

.fixed-left-side {
    left: 0;
}

.fixed-right-side {
    right: 0;
}

.container-left-side {
    height: 100%;
    display: flex;
    align-items: center;
}

.bottom-right-side {
    position: absolute;
    bottom: 0;
    right: 120px;
    z-index: 1;
}

.white-logo {
    display: none;
}

.video-gtls {
    width: 100%;
    height: 700px;
}

.text-blue {
    color: #2589b3;
}

.link-black {
    color: #333;
}

/* menu */

#menu {
    width: 160px;
    position: absolute;
    right: 0;
    padding: 0;
    list-style-type: none;
    text-transform: uppercase;
}

#menu li a {
    display: block;
    padding: 10px 0;
    font-size: 12px;
    color: #5388b0;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 2px;
    transition: .3s;
}

#menu .text-white a {
    color: #ffffff;
}

#menu .text-white a:hover,
#menu li a:hover {
    color: #eb3e4a;
    text-decoration: none;
}

#menu .active-item a {
    color: #cccccc;
}

.logo {
    width: 160px;
    position: absolute;
    right: 0;
    margin-top: 150px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 80px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0;
    padding: 30px 0;
    letter-spacing: 1px;
}

.btn-danger {
    background-color: #d42e27;
    border-color: #d42e27;
}

.btn-primary {
    background-color: #2589b3;
    border-color: #2589b3;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    background-color: #246986;
    border-color: #246986;
}

.btn-relative {
    position: relative;
}

.text-small {
    font-size: 14px;
    line-height: 22px;
}

.btn-consultation-position {
    position: absolute;
    right: 0;
    top: 50%;
    height: 80px;
    width: 320px;
    z-index: 1030;
}

.scroll-down {
    animation-name: scroll;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.line-dot {
    position: relative;
    height: 125px;
    width: 10px;
}

.line-dot:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: #eb3e4a;
}

.line-dot:after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 0 0 20px 20px;
    height: 80px;
    width: 10px;
    background: #2589b3;
}

.backg-white.line-dot:after {
    background: #ffffff;
}

.phone-number {
    margin-top: 32px;
}

.phone-number a {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    transition: .3s;
}

.text-white a {
    color: #ffffff;
}

.phone-number a:hover,
.text-white a:hover {
    color: #eb3e4a;
    text-decoration: none;
}

.wrap-fixed-content {
    position: fixed;
    z-index: 1;
    width: 100%;
}

.scroll {
    position: absolute;
    width: 100%;
    height: 64px;
    bottom: 0;
    left: 0;
}

.text-big {
    font-size: 24px;
    line-height: 36px;
}

.fixed-height-bottom {
    height: 34px;
}

.copyright-design {
    font-size: 10px;
    font-weight: 600;
}

.social-media {
    position: absolute;
    bottom: 30px;
    z-index: 1040;
    left: 150px;
}

.social-media a {
    padding: 5px;
    margin-right: 40px;
    color: #5388b0;
    text-decoration: none;
    transition: .3s;
}

.social-media a:hover {
    color: #eb3e4a;
}

/** fade effect **/

.fp-section {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all 0.7s ease-in-out;
}

.fp-section.active {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

/** end fade effect**/

/** 1section **/

.section-start {
    background: url(gtls-sklad.jpg) center center no-repeat;
    background-size: cover;
}

.modal-video-btn {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    transition: .3s;
}

.modal-video-btn:hover {
    box-shadow: inset 0px 0px 0px 3px rgb(255, 255, 255);
    border-radius: 100%;
}

.modal-content {
    max-width: 1200px;
    background: none;
    border: none;
}

.modal-backdrop.show {
    opacity: 0.8;
}

.close {
    text-align: right;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: .7;
}

button:focus {
    outline: none;
}

.modal-dialog {
    height: 100%;
    max-width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-section-1 {
    position: relative;
    z-index: 1;
}

.title-main {
    font-size: 60px;
    font-weight: 800;
    color: #eb3e4a;
    font-family: 'Proxima Nova Bl';
}

.title-secondary {
    position: relative;
    opacity: 1;
    font-size: 40px;
    font-weight: 800;
    color: #eb3e4a;
    font-family: 'Proxima Nova Bl';
}

/** 2 section **/

.imgs-block-section2 {
    position: relative;
    height: 100%;
    width: 100%;
}

.img-sklad {
    display: block;
    position: relative;
    right: -20px;
    top: 260%;
}

.title-section2 {
    margin: 200px 0 100px;
}

.img-map {
    display: block;
    position: relative;
    right: 165px;
    top: -205%;
}

.text-section2 {
    position: relative;
    right: -80%;
}

.text-middle-font {
    font-size: 20px;
    line-height: 30px;
}

.text-experience:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 56px;
    left: -140px;
    background: url(ico-delivery.png);
}

/** section 3 **/

.title-secondary-position-section3 {
    top: -12px;
}

.item-advantages {
    position: relative;
    right: -180%;
}

.item-advantages div {
    flex-wrap: wrap;
    background: #fff;
    height: 290px;
    max-width: 220px;
    padding: 20px;
    margin: 0 5px;
    font-weight: 500;
    overflow: hidden;
    transition: .3s;
}

.item-advantages div:hover {
    transform: translateY(-5px);
    -webkit-box-shadow: 0px 0px 36px 0px rgba(111, 133, 149, 0.35);
    ;
    -moz-box-shadow: 0px 0px 36px 0px rgba(111, 133, 149, 0.35);
    ;
    box-shadow: 0px 0px 36px 0px rgba(111, 133, 149, 0.35);
    ;
}

.section3-content {
    height: 400px;
    background: url(./images/section3-bg.jpg) 120px top no-repeat;
}

.btn-price {
    margin-bottom: 145px;
}

.btn-price a {
    display: flex;
    left: 0px;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 240px;
    left: -200%;
}

/** section4 **/

.section4-bg {
    background: url(./images/section4-bg.png) right bottom 85px no-repeat;
}

.section4-item {
    position: relative;
    right: -300%;
}

.section4-item-block-1,
.section4-item-block-2,
.section4-item-block-3,
.section4-item-block-4 {
    position: relative;
    width: 300px;
    height: 400px;
    color: #ffffff;
    padding: 10px 40px;
    margin: 5px;
    border: 1px solid #dae1e6;
    transition: .3s;
}

.section4-item-block-1 {
    background: url(section4-item-block-1-bg.png)#0f1e32 top center no-repeat;
}

.section4-item-block-2 {
    background: url(section4-item-block-2-bg.png)#0f1e32 top center no-repeat;
}

.section4-item-block-3 {
    background: url(section4-item-block-3-bg.png)#0f1e32 top center no-repeat;
}

.section4-item-block-4 {
    background: url(section4-item-block-4-bg.png)#0f1e32 top center no-repeat;
}

.card-body {
    padding: 0;
    height: 0;
    opacity: 0;
    transition: .3s;
}

.card {
    background-color: transparent;
}

[class*='section4-item-block']:hover {
    transform: scale(1.1);
    z-index: 2;
}

[class*='section4-item-block']:hover .card-body {
    height: 180px;
    opacity: 1;
}

[class*='section4-item-block']:hover .plus-minus::before {
    opacity: 0;
}

.plus-minus {
    position: relative;
    width: 40px;
    height: 2px;
    background: #eb3e4a;
}

.plus-minus::before {
    content: '';
    height: 40px;
    width: 2px;
    background: #eb3e4a;
    position: absolute;
    left: 20px;
    top: -20px;
    transition: .3s;
}

.wrap-section4-item h4 {
    height: 60px;
    font-size: 20px;
}

.wrap-section4-item .text-small {
    font-size: 12px;
    line-height: 18px;
}

.col-section4-subtitle {
    display: none;
}

.title-section4-position-left {
    margin-left: 550px;
}

/** section5 **/

.section5-bg {
    color: #ffffff;
}

.title-dark {
    font-weight: 800;
    color: #ffffff;
}

.section5-bg {
    background: url(./images/section5-bg.png) center center no-repeat;
}

.section5-location {
    position: relative;
    right: -200%;
}

.block-animate-text-left {
    position: relative;
    top: -800px;
}

.block-animate-text-right {
    position: relative;
    bottom: -800px;
}

.contact-information {
    font-size: 12px;
    line-height: 18px;
}

.section5-md-show {
    display: block;
}

.contact-information p {
    width: 300px;
}

.section5-position-text {
    margin-bottom: -200px;
}

.section5-md-show {
    display: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
    height: 4px;
    width: 4px;
    margin: -2px 0 0 -2px;
    border-radius: 100%;
    background: #e9515c;
}

.section5-md-hide .section5-subtitle {
    margin-top: 15px;
    line-height: 16px;
}

/** section6 **/

.title-customer-feedback {
    position: absolute;
    left: 0px;
    top: 10%;
    transition: .6s;
}

.title-section6-show {
    display: none;
}

.customer-feedback {
    position: relative;
    max-width: 350px;
    cursor: pointer;
}

.customer-feedback:before {
    content: 'Â«';
    font-size: 30px;
    position: absolute;
    top: -5px;
    left: -20px;
}

.customer-feedback:after {
    content: 'Â»';
    font-size: 30px;
    position: absolute;
    bottom: 0;
    right: -20px;
}

/**/

.customer-position {
    font-size: 12px;
    font-style: italic;
}

.customer-corporation {
    font-size: 21px;
    font-weight: 800;
}

.carousel-caption .text-small {
    line-height: 18px;
}

.title-block6 {
    margin: -60px 0 60px;
}

.logo-section6 {
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    height: 100%;
}

.fp-controlArrow {
    display: block !important;
}

.fp-controlArrow.fp-prev {
    position: absolute;
    top: calc(50% + 41px);
    left: calc(50% - 160px);
    width: 80px;
    height: 80px;
    background: #2589b3;
    border: none;
}

.fp-controlArrow.fp-prev:before {
    content: '';
    display: block;
    background: url(./images/arrow-prev.png) center center no-repeat;
    height: 80px;
    width: 80px;
}

.fp-controlArrow.fp-next {
    position: absolute;
    top: calc(50% + 41px);
    right: 50%;
    width: 80px;
    height: 80px;
    background: #2589b3;
    border: none;
}

.fp-controlArrow.fp-next.disabled,
.fp-controlArrow.fp-prev.disabled {
    background: #ccc !important;
}

.fp-controlArrow.fp-next:before {
    content: '';
    display: block;
    background: url(./images/arrow-next.png) center center no-repeat;
    height: 80px;
    width: 80px;
}

.fp-slidesNav {
    position: absolute;
    top: calc(50% + 250px);
    z-index: 4;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: calc(50% - 210px) !important;
}

.w-left-side,
.w-right-slide {
    width: 50%;
}

.wrap-left-content {
    width: 200px;
}

.title-customer-feedback {
    margin-bottom: -140px;
}

.fp-controlArrow:hover {
    background: #e9515c !important;
}

/** section7 **/

.btn-blue-red {
    color: #ffffff;
    background-color: #2589b3;
    border-color: #2589b3;
}

.btn-blue-red:hover,
.btn-blue-red:active {
    color: #ffffff;
    background-color: #eb3e4a;
    border-color: #eb3e4a;
}

.btn-blue-red:focus {
    box-shadow: 0 0 0 0.2rem #ff00004f;
}

.form-custom .form-control {
    border-radius: 0;
    border: 1px solid #ced4da00;
    border-bottom: 1px solid #cccccc;
}

.form-custom .form-control:focus {
    color: #ffffff;
    background-color: #ffffff;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    background: #2589b3;
}

.download-price-btn {
    position: absolute;
    right: 0;
    top: 50%;
    height: 80px;
    width: 320px;
    z-index: 1030;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-send {
    margin-top: 100px;
}

.m-section7-title {
    margin: -100px 0 30px;
}

.table-title {
    line-height: 10px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 800;
}

.table-title-content {
    font-size: 20px;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 4px solid;
    font-family: 'Proxima Nova Th';
    font-weight: bold;
}

.table-item-last {
    border-bottom: 1px solid;
    padding-bottom: 20px;
}

#priceModal {
    .modal-dialog {
        align-items: flex-start;
        margin: 40px;
    }
    .modal-content {
        position: relative;
        background: #ffffff;
        min-width: 1200px;
        border-radius: 0;
        padding: 80px 160px 80px 80px;
        .modal-body {
            .row {
                margin: 0;
                .col-7 {
                    padding-right: 25px;
                }
            }
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            display: flex;
            .modal-left-side {
                width: 400px;
                h3 {
                    margin: 60px 0 40px;
                    font-weight: 800;
                    font-size: 30px;
                    font-family: 'Proxima Nova Th';
                }
            }
            .modal-right-side {
                color: #333333;
                width: 800px;
            }
        }
        .close {
            position: absolute;
            top: 80px;
            right: 80px;
            color: #d42e27;
        }
    }
}

.feedbackModal {
    font-family: 'Roboto';
    .modal-dialog {
        align-items: flex-start;
        margin: 40px;
    }
    .modal-content {
        position: relative;
        background: #ffffff;
        min-width: 1200px;
        border-radius: 0;
        padding: 80px 160px 80px 80px;
        .modal-body {
            .row {
                margin: 0;
                .col-7 {
                    padding-right: 25px;
                }
            }
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            display: flex;
            .modal-left-side {
                width: 400px;
                padding: 0 80px 0 0;
                h3 {
                    margin: 60px 0 40px;
                    font-weight: 800;
                    font-size: 30px;
                }
                .wrap-left-content {
                    margin-top: 40px;
                    width: auto;
                    line-height: 1.5;
                }
            }
            .modal-right-side {
                color: #333333;
                width: 800px;
            }
        }
        .close {
            position: absolute;
            top: 80px;
            right: 80px;
            color: #d42e27;
        }
    }
}

/** section8 **/

.section8-bg {
    background: url(./images/map-footer.png) center center no-repeat;
    background-size: cover;
}

.footer-location h2,
.footer-location a {
    color: #ffffff;
    font-family: 'Proxima Nova Bl';
    font-size: 40px;
}

.footer-location a:hover {
    color: #eb3e4a;
}

.text-location8-position {
    width: 50%;
    margin-left: 50%;
    .text-middle-font {
        margin-top: 30px;
    }
}

/* for IE*/

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .container-left-side {
        height: 50%;
    }
}

@media all and (max-width: 1600px),
screen and (max-height: 700px) {
    .title-main {
        font-size: 48px;
    }
    .fixed-left-side,
    .fixed-right-side {
        width: 220px;
    }
    .btn,
    .btn-consultation-position,
    .download-price-btn {
        font-size: 14px;
    }
    .text-middle-font {
        font-size: 20px;
        line-height: 1.2;
        max-width: 400px;
    }
    .text-section2 {
        .text-middle-font {
            line-height: 1.5;
            max-width: 550px;
        }
        .text-small {
            max-width: 550px;
        }
    }
    .title-section2 {
        margin: 100px 0 40px;
    }
    .logo {
        margin-top: 100px;
    }
    .phone-number {
        margin-top: 10px;
    }
    .line-dot {
        height: 90px;
    }
    .line-dot:after {
        height: 60px;
    }
    .section {
        padding: 0 110px 0 220px;
    }
    .social-media {
        left: 65px;
    }
    .scroll {
        display: none;
    }
    .title-secondary {
        font-size: 32px;
    }
    /*section2*/
    .col-md-custom-70 {
        width: 70% !important;
    }
    .col-md-custom-30 {
        width: 30% !important;
    }
    .img-map {
        right: 0;
    }
    .img-sklad {
        right: -220px;
        top: -65px;
    }
    /*section3*/
    .item-advantages div {
        font-size: 16px;
        line-height: 1.5;
    }
    .section3-content {
        flex-wrap: wrap;
        height: 500px;
        align-items: center !important;
    }
    .btn-price {
        margin-bottom: 0;
        position: absolute;
        bottom: 25px;
        left: 115px;
    }
    .container-advantages {
        margin-left: 100px;
    }
    .container-advantages .mb-5,
    .container-advantages .my-5 {
        margin-bottom: 1rem !important;
    }
    .container-advantages .offset-md-1:not(:nth-child(2)) {
        margin-left: 0;
    }
    .title-secondary-position-section3 {
        padding-left: 120px;
    }
    /*section 4*/
    .section4-item-block-1,
    .section4-item-block-2,
    .section4-item-block-3,
    .section4-item-block-4 {
        min-width: 230px;
    }
    .section4-item {
        margin-bottom: 0 !important;
    }
    .col-section4 {
        margin-left: 200px;
    }
    .row-section4-bottom {
        display: none;
    }
    .col-section4-subtitle {
        display: block;
    }
    /*section 5*/
    .section5-md-hide {
        display: none;
    }
    .section5-md-show {
        display: block;
    }
    .title-section5 {
        margin: -10px 0 0 140px;
    }
    .section5-subtitle {
        margin: 0;
        line-height: 15px;
        margin-top: 15px;
    }
    .section5-text-title {
        margin-left: 35px;
    }
    /*section6*/
    .section6 {
        padding: 0 220px 0 220px;
        .title-secondary {
            top: 0;
        }
    }
    .logo-section6 {
        display: none !important;
    }
    /*section7*/
    .m-section7-title {
        margin: 0 0 30px 0;
    }
    /*section8*/
    .text-location8-position {
        width: 100%;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .footer-location {
        margin: 0;
    }
}

.top-right-side {
    width: 160px;
    right: 0;
    position: absolute;
    z-index: 1;
}

@media all and (max-width: 1366px),
screen and (max-height: 700px) {
    .wrap-section4-item .text-small {
        font-size: 10px;
    }
    #menu li a {
        line-height: 25px;
    }
    .modal-content {
        max-width: 800px;
    }
    .video-gtls {
        height: auto;
    }
    .row-section4,
    .row-section4 .title-secondary {
        margin-bottom: 15px !important;
    }
    .section3-content {
        height: 460px;
    }
    .btn-price {
        bottom: 5px;
    }
    .container-advantages .mb-5,
    .container-advantages .my-5 {
        margin-bottom: 0 !important;
    }
    .copyright-design.mb-4 {
        margin-bottom: 0 !important;
    }
    .social-media {
        bottom: 10px;
    }
    .wrap-section4-item h4 {
        font-size: 16px;
    }
    .wrap-section4-item .text-small {
        font-size: 10px;
    }
    .section4-item-block-1,
    .section4-item-block-2,
    .section4-item-block-3,
    .section4-item-block-4 {
        height: 360px;
    }
    .wrap-section4-item h4 {
        margin-bottom: 10px !important;
    }
    .section5-md-show .row {
        margin: 15px 0 -20px 0 !important;
    }
    .section5-location img {
        max-width: 220px;
    }
    .block-animate-text-left {
        padding-left: 15px;
    }
    .section5-subtitle {
        margin-top: 0;
    }
    .title-customer-feedback {
        margin-bottom: -60px;
    }
    .slide-left-content.mt-5 {
        margin-top: 10px !important;
    }
    .fp-slidesNav {
        top: calc(50% + 270px);
    }
    .fp-controlArrow.fp-next,
    .fp-controlArrow.fp-prev {
        top: calc(50% + 116px);
    }
    .title-main-md-size {
        margin-left: 120px;
    }
    .title-section6-show {
        display: block;
        margin-bottom: -60px;
    }
    .title-section6-hide {
        display: none;
    }
    .col-section4-subtitle.text-middle-font {
        max-width: 550px;
    }
}

@media all and (max-width: 1300px) {
    [class*='section4-item-block']:hover {
        .card-body {
            height: 240px;
        }
        .plus-minus {
            margin-top: 10px !important;
        }
        h4 {
            height: 40px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .fixed-left-side {
        height: 200px;
        .container-left-side {
            display: none;
        }
    }
    .section {
        padding: 0;
    }
    .title-main-md-size {
        margin-left: 0;
    }
    .btn-consultation-position {
        bottom: 20%;
        top: auto;
    }
    .download-price-btn {
        display: none;
    }
    .phone-number a {
        font-size: 24px;
    }
    .top-right-side {
        width: 250px;
    }
    .copyright-design {
        font-size: 14px;
    }
    .bottom-right-side {
        right: 40px;
    }
    .modal-content {
        min-width: 600px;
    }
    .container-advantages {
        margin-left: 60px;
        .btn-price {
            bottom: -40px;
            right: 0;
            left: auto;
            z-index: 2;
        }
    }
    .title-secondary-position-section3 {
        padding-left: 0;
        text-align: left !important;
    }
    .section3-content {
        height: auto;
    }
    .item-advantages {
        flex-wrap: wrap;
    }
    .item-advantages div {
        margin-top: 10px;
    }
    .col-section4 {
        margin-left: 60px;
        padding-left: 15px;
        margin-top: 100px;
    }
    .section4-item-block-1,
    .section4-item-block-2,
    .section4-item-block-3,
    .section4-item-block-4 {
        max-width: 45%;
    }
    .section4-item {
        flex-wrap: wrap;
    }
    .wrap-section4-item .text-small {
        font-size: 12px;
    }
    .download-price-btn {
        max-width: 200px;
        a {
            max-width: 200px;
        }
    }
    .title-section5 {
        margin: 0;
    }
    .section5-text-title {
        margin-left: 0;
        font-size: 14px;
    }
    .section5-md-show {
        .block-animate-text-left {
            padding-left: 0;
        }
    }
    .modal {
        .modal-content {
            min-width: 600px !important;
            padding: 40px !important;
            .close {
                z-index: 999 !important;
            }
        }
    }
    .section6 .title-secondary {
        top: 15%;
    }
    .fp-controlArrow.fp-prev {
        left: 60px;
        top: auto;
        bottom: 10%;
        z-index: 999 !important;
    }
    .fp-controlArrow.fp-next {
        right: 60px;
        top: auto;
        bottom: 10%;
        z-index: 999 !important;
    }

}